import React, { useEffect, useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import FanHubPreviewContents from '@/components/promote-modals/WebsiteGeneratorModal/website-generator-steps/FanHubPreviewContents';
import { Images } from '@/constants/Images';
import { WebsiteBuilderBackgrounds } from '@/constants/WebsiteBuilderBackgrounds';
import useArtist from '@/hooks/artist/useArtist';
import {
  WebsiteBuilderBackgroundColorModel,
  WebsiteBuilderBackgroundImageModel,
  WebsiteBuilderPrimaryLinksModel,
} from '@/models/WebsiteBuilder';

import { DesktopScreen } from './DesktopScreen';
import { MobileScreen } from './MobileScreen';
import { TabletScreen } from './TabletScreen';

export const ExternalFanHubScreenPreviews = ({ isInternal }: { isInternal?: boolean }) => {
  const [params] = useSearchParams();
  const { artist } = useArtist();
  const [chosenBackground, setChosenBackground] = useState<
    WebsiteBuilderBackgroundImageModel | WebsiteBuilderBackgroundColorModel
  >();
  const [links, setLinks] = useState<WebsiteBuilderPrimaryLinksModel[]>([]);
  const screen = params.get('screen') || 'all';
  const artistName = params.get('artistName') || artist?.details.name || 'Obongjayar';
  const image =
    params.get('image') ||
    artist?.details.profileImageUrl ||
    'https://i.scdn.co/image/ab6761610000e5eb7b082c235d7920aa8a817cd1';
  const primaryLinks = params.get('primaryLinks');
  const bio = params.get('bio') || '';
  const background = params.get('background') || 'dark-blur-blue-marbled';

  if (!isInternal) {
    document.body.setAttribute('style', 'background: transparent;');
    document.getElementsByClassName('App')[0]?.setAttribute('style', 'padding: 0px !important;');
  }

  const defaultPrimaryLinks = useMemo(() => {
    return [
      { customText: 'Tickets for our tour', link: '' },
      { customText: 'Listen to our music', link: '' },
      { customText: 'Join our mailing list', link: '' },
    ];
  }, []);

  const loremIpsum =
    'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam nec purus nec nunctincidunt tincidunt. Nullam nec purus nec n';

  useEffect(() => {
    if (background) {
      const { images, colors } = WebsiteBuilderBackgrounds;
      const allBackgrounds = [...images, ...colors];
      const matchedBackground = allBackgrounds.find((item) => item.name === background || item.name === background);
      if (matchedBackground) {
        setChosenBackground(matchedBackground);
      }
    }
  }, [background]);

  useEffect(() => {
    if (primaryLinks) {
      const newLinks = JSON.parse(primaryLinks) as WebsiteBuilderPrimaryLinksModel[];
      setLinks(newLinks);
    } else {
      setLinks(defaultPrimaryLinks);
    }
  }, [defaultPrimaryLinks, primaryLinks]);

  const contents = (
    <FanHubPreviewContents
      artistName={artistName || ''}
      image={image || Images.profilePlaceholder}
      primaryLinks={links}
      bio={bio || loremIpsum}
      chosenBackground={chosenBackground}
    ></FanHubPreviewContents>
  );

  if (screen === 'desktop') {
    return (
      <>
        <DesktopScreen>{contents}</DesktopScreen>
      </>
    );
  }

  if (screen === 'mobile') {
    return (
      <>
        <MobileScreen>{contents}</MobileScreen>
      </>
    );
  }

  if (screen === 'tablet') {
    return (
      <>
        <TabletScreen>{contents}</TabletScreen>
      </>
    );
  }

  if (screen === 'all') {
    return (
      <>
        <div className="pos-rel d-flex jc-center">
          <div className={`mobile-group ${isInternal ? 'internal' : ''}`}>
            <MobileScreen>{contents}</MobileScreen>
          </div>
          <div className="desktop-group">
            <DesktopScreen>{contents}</DesktopScreen>
          </div>
          <div className={`tablet-group ${isInternal ? 'internal' : ''}`}>
            <TabletScreen>{contents}</TabletScreen>
          </div>
        </div>
      </>
    );
  }

  if (screen === 'unbound-mobile') {
    return <div className="w100p h-fill mobile-screen unbound-mobile">{contents}</div>;
  }

  return <div className="w100p h-fill default-screen">{contents}</div>;
};
