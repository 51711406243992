import React, { useEffect, useState } from 'react';

import { Button, Icon, Modal } from '@mui/material';

import MetaAdsModal from '@/components/promote-modals/MetaAdsModal';
import TikTokAdsModal from '@/components/promote-modals/TikTokAdsModal';

const SocialAdsButtons = ({
  hideButton,
  isOpenMeta,
  isOpenTikTok,
  close,
}: {
  hideButton?: boolean;
  isOpenMeta?: boolean;
  isOpenTikTok?: boolean;
  close?: () => void;
}) => {
  const [open, setOpen] = useState<boolean>(false);
  const [modalItem, setModalItem] = useState<string>();

  const handleOpenModal = (name: string) => {
    setModalItem(name);
    setOpen(true);
  };

  const handleModalClose = () => {
    setOpen(false);
    setModalItem(undefined);
    close && close();
  };

  useEffect(() => {
    if (isOpenMeta) {
      handleOpenModal('meta');
    }
    if (isOpenTikTok) {
      handleOpenModal('tiktok');
    }
  }, [isOpenMeta, isOpenTikTok]);

  return (
    <>
      <Modal
        open={open}
        onClose={() => {
          setOpen(false);
        }}
      >
        <>
          {modalItem === 'meta' && <MetaAdsModal closeModalOutput={handleModalClose} />}
          {modalItem === 'tiktok' && <TikTokAdsModal closeModalOutput={handleModalClose} />}
        </>
      </Modal>
      {!hideButton && (
        <Button
          className={`m0 btn-white flex-grow`}
          onClick={() => {
            handleOpenModal('meta');
          }}
        >
          <Icon>add</Icon>
          <span className="pl8">New Meta ad</span>
        </Button>
      )}
      {!hideButton && (
        <Button
          className={`m0 btn-white ml10 flex-grow`}
          onClick={() => {
            handleOpenModal('tiktok');
          }}
        >
          <Icon>add</Icon>
          <span className="pl8">New TikTok ad</span>
        </Button>
      )}
    </>
  );
};

export default SocialAdsButtons;
