import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';

import { Button, Icon } from '@mui/material';

import useIntercomContext from '@/hooks/context/useIntercomContext';

import { navItemsExpanded } from '../../../constants/NavModel';
import Logout from '../auth/Logout';

const MenuModal = ({ closeModalOutput }: { closeModalOutput: () => void }) => {
  const { t } = useTranslation();
  const [showIntercom, setShowIntercom] = useState<boolean>(false);

  const { isOpen, dispatchActivateIntercom, dispatchDeactivateIntercom, dispatchOpenIntercom } = useIntercomContext();

  const handleIntercom = () => {
    setShowIntercom(true);
    dispatchActivateIntercom('navbar');
    dispatchOpenIntercom();
  };

  useEffect(() => {
    if (!isOpen) {
      setShowIntercom(false);
    }
  }, [dispatchOpenIntercom, isOpen, showIntercom]);

  useEffect(() => {
    if (!showIntercom) {
      dispatchDeactivateIntercom('navbar');
    }
  }, [dispatchDeactivateIntercom, showIntercom]);

  useEffect(() => {
    return () => {
      dispatchDeactivateIntercom('navbar');
    };
  }, [dispatchDeactivateIntercom]);

  const closeModal = () => {
    closeModalOutput();
  };
  return (
    <div className="menu-modal-container overflow-scroll">
      <Button
        className="icon-btn menu-close-btn"
        onClick={() => {
          closeModal();
        }}
      >
        <Icon>close</Icon>
      </Button>
      <div className="d-flex flex-wrap gap10 pt58 p10">
        {navItemsExpanded.map((item) => {
          return (
            <NavLink
              key={item.title}
              className={`flex-w50p-21 flex-grow p10 m0 card-inner-inner text-center pos-rel text-dec-none max-w50p nav-card ${item.name}`}
              data-testid={`nav-item-${item.name}`}
              to={item.name === 'support' ? '' : item.navLink}
              onClick={() => {
                if (item.name === 'support') {
                  return handleIntercom();
                }
                closeModal();
              }}
            >
              <img
                src={item.icon}
                alt={item.name}
                height={item.name === 'release-cycles' ? 120 : 90}
                className={`pos-abs l50p trans-h-50 ${item.name === 'release-cycles' ? 't0' : ''}`}
              />
              <p className="fw-bold text-left pl10 mt100 text-dec-none small">{t(item.title)}</p>
            </NavLink>
          );
        })}
        <Button className="btn-white w100p m0 text-center">
          <Logout inButton isInMenu />
        </Button>
      </div>
    </div>
  );
};

export default MenuModal;
