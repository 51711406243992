import React from 'react';

import { Button } from '@mui/material';

import useAccountContext from '@/hooks/context/useAccountContext';

import CustomIcons from '../microcomponents/CustomIcons';

const TikTokLoginButton = ({
  isWide,
  isInner,
  isConnect,
}: {
  isWide?: boolean;
  isInner?: boolean;
  isConnect?: boolean;
}) => {
  const { refetchAccount } = useAccountContext();
  const redirect_uri = `${window.location.origin}/tiktok-callback`;
  const appID = import.meta.env.VITE_TIKTOK_APP_ID;
  const state = '';

  const route = `https://business-api.tiktok.com/portal/auth?app_id=${appID}&state=${state}&redirect_uri=${redirect_uri}`;

  const loginToTikTok = () => {
    window.open(`${route}`, '', 'popup=true,width=500px,height=800px');
    window.addEventListener(
      'message',
      (event) => {
        if (event.data === 'TikTok logged in') {
          refetchAccount();
        }
      },
      true
    );
  };

  return (
    <>
      {isConnect && (
        <Button
          className={`btn-white ${isWide ? 'w100p mt10' : 'min-w160 mt-auto'} m0  mb-auto ml-auto`}
          onClick={loginToTikTok}
        >
          <CustomIcons className="text-black mt4 pr8" name="tikTokLogo" />
          Connect account
        </Button>
      )}
      {!isConnect && (
        <Button className={`m0 ${isWide ? 'w100p mt10' : ''} ${isInner ? 'btn-white' : ''}`} onClick={loginToTikTok}>
          Login to TikTok
        </Button>
      )}
    </>
  );
};

export default TikTokLoginButton;
