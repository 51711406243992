import React from 'react';
import { useTranslation } from 'react-i18next';

import Icon from '@mui/material/Icon';

import useAccountContext from '@/hooks/context/useAccountContext';

import ButtonComponent from '../microcomponents/Button';

const Logout = ({ minimize, inButton, isInMenu }: { minimize?: boolean; inButton?: boolean; isInMenu?: boolean }) => {
  const { t } = useTranslation();

  const { logoutUser } = useAccountContext();

  return (
    <ButtonComponent
      isCustom
      className={`${minimize ? 'logout-button minimize' : 'logout-button p8'} text-white w100p ${isInMenu ? 'jc-center' : ''}`}
      onClick={() => {
        logoutUser();
      }}
      data-testid="sign-out-button"
    >
      <Icon className={`logout-icon ${isInMenu ? 'text-black' : 'text-white'} ${inButton ? 'pt0' : ''}`}>logout</Icon>
      {!minimize && (
        <p className={`logout-text ${isInMenu ? 'text-black' : 'text-white'} ${inButton ? 'small pt4' : ''} `}>
          {t('COMMON.SIGN-OUT')}
        </p>
      )}
    </ButtonComponent>
  );
};

export default Logout;
