import React, { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

import { Dialog, DialogActions, DialogContent, DialogTitle, Icon } from '@mui/material';

import Button from '../microcomponents/Button';

export type WalkthroughModalProps = {
  imageUrl?: string;
  title: string;
  content: ReactNode;
  isOpen?: boolean;
  onClose?: () => void;
  nextText?: string;
  onNext?: () => void;
  skipText?: string;
  onSkip?: () => void;
};

export const WalkthroughModal: React.FC<WalkthroughModalProps> = ({
  imageUrl,
  title,
  content,
  isOpen,
  onClose,
  nextText,
  onNext,
  skipText,
  onSkip,
}) => {
  const { t } = useTranslation();

  if (typeof title === 'string') {
    title = t(title);
  }
  if (typeof content === 'string') {
    content = t(content);
  }
  if (typeof nextText === 'string') {
    nextText = t(nextText);
  }
  if (typeof skipText === 'string') {
    skipText = t(skipText);
  }

  return (
    <Dialog className="p10" open={isOpen || false} onClose={onClose}>
      <Button className="icon-btn btn-back m0 mr0 ml-auto pos-abs r8 t8" aria-label="Close" onClick={onClose}>
        <Icon>close</Icon>
      </Button>
      <DialogTitle className="d-flex flex-wrap mt-auto jc-vertical mb-auto gap16 pl4 pr4 pb0">
        {imageUrl && <img className="max-w200 max-h200 m-auto" src={imageUrl} alt={title} />}
        <h4 className="w100p">{title}</h4>
      </DialogTitle>
      <DialogContent className="p0 pl4 pr4">
        <p className="text-faded">{content}</p>
      </DialogContent>
      {(nextText || skipText) && (
        <DialogActions className="pt16 pl4 pr4 pb0 d-flex flex-wrap mt-auto jc-vertical">
          {nextText && (
            <Button className="pt4 btn-white w100p m0 p16" onClick={onNext}>
              {nextText}
            </Button>
          )}
          {nextText && skipText && <div className="p8 divider" />}
          {skipText && (
            <Button className="pt4 btn-black-2 w100p m0 p16" onClick={onSkip}>
              {skipText}
            </Button>
          )}
        </DialogActions>
      )}
    </Dialog>
  );
};
