import axios, { isAxiosError } from 'axios';
import mixpanel from 'mixpanel-browser';

import { SnackbarModel } from '@/contexts/SnackBarContext';

import { handleUnknownError, isDevelopment } from './generic';

export const initApi = (baseUrl: string) => {
  axios.defaults.baseURL = baseUrl;
  axios.defaults.headers.common['Platform-Type'] = 'Unhurd/Web';
  axios.defaults.headers.common['Platform-Version'] = '2.2.2';
};

export const initToken = (token?: string) => {
  if (token) {
    axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
  } else {
    axios.defaults.headers.common['Authorization'] = '';
  }
};

export const handleApiError = ({
  error,
  dispatchSnackbar,
  customMessage,
}: {
  error: unknown;
  dispatchSnackbar?: (value: SnackbarModel) => void;
  customMessage?: string;
}) => {
  if (!dispatchSnackbar) {
    if (isDevelopment) {
      // eslint-disable-next-line no-console
      console.log(error);
    }
    return;
  }

  if (customMessage) {
    dispatchSnackbar({
      type: 'OPEN_SNACKBAR',
      payload: {
        message: customMessage,
        type: 'error',
      },
    });
    return;
  }

  if (isAxiosError(error) && error.response?.data.errorMessage) {
    dispatchSnackbar({
      type: 'OPEN_SNACKBAR',
      payload: {
        message: error.response.data.errorMessage,
        type: 'error',
      },
    });
    return;
  }

  handleUnknownError(dispatchSnackbar);
};

export const interceptMixpanelRequests = () => {
  // Intercept and modify mixpanel.track function
  const originalTrack = mixpanel.track;

  mixpanel.track = (eventName: string, properties?: Record<string, object>) => {
    // If properties are provided, remove the `request-id` from them
    if (properties && (properties['request-id'] || properties['Request-Id'])) {
      delete properties['request-id']; // Remove `request-id` if present
      delete properties['Request-Id']; // Remove `Request-Id` if present
    }

    // Call the original track function with modified properties
    originalTrack.call(mixpanel, eventName, properties);
  };

  // Intercept XMLHttpRequest (if Mixpanel uses it internally)
  const originalOpen = XMLHttpRequest.prototype.open;

  XMLHttpRequest.prototype.open = function (_, url) {
    // Check if the request is being made to the Mixpanel endpoint
    if (
      (url as string).includes('mixpanel.com') ||
      (url as string).includes('58qr5yci46.execute-api.us-east-1.amazonaws.com/v1/')
    ) {
      const originalSetRequestHeader = this.setRequestHeader;
      this.setRequestHeader = function (name, value) {
        // Remove `request-id` header if it's being sent to Mixpanel
        if (name === 'request-id') {
          return;
        }
        if (name === 'Request-Id') {
          return;
        }
        if (name === 'traceparent') {
          return;
        }
        if (name === 'Traceparent') {
          return;
        }
        if (name === 'Request-Context') {
          return;
        }
        if (name === 'request-context') {
          return;
        }
        if (name === 'X-Account-Id') {
          return;
        }
        if (name === 'x-account-id') {
          return;
        }
        // Call the original method to set other headers
        originalSetRequestHeader.call(this, name, value);
      };
    }

    type arguments = [
      method: string,
      url: string | URL,
      async: boolean,
      username?: string | null | undefined,
      password?: string | null | undefined,
    ];
    // Call the original open method
    originalOpen.apply(this, arguments as unknown as arguments);
  };

  // Intercept global fetch (if Mixpanel uses it)
  const originalFetch = window.fetch;

  window.fetch = async (input: RequestInfo | URL, init?: RequestInit) => {
    // Check if the request is being made to the Mixpanel endpoint
    if (
      typeof input === 'string' &&
      (input.includes('mixpanel.com') || input.includes('58qr5yci46.execute-api.us-east-1.amazonaws.com/v1/'))
    ) {
      if (
        init &&
        init.headers &&
        ((init.headers as Record<string, string>)['request-id'] ||
          (init.headers as Record<string, string>)['Request-Id'] ||
          (init.headers as Record<string, string>)['traceparent'] ||
          (init.headers as Record<string, string>)['Traceparent'] ||
          (init.headers as Record<string, string>)['request-context'] ||
          (init.headers as Record<string, string>)['Request-Context'] ||
          (init.headers as Record<string, string>)['x-account-id'] ||
          (init.headers as Record<string, string>)['X-Account-Id'])
      ) {
        // Remove the request-id from the headers
        delete (init.headers as Record<string, string>)['request-id'];
        delete (init.headers as Record<string, string>)['Request-Id'];
        delete (init.headers as Record<string, string>)['traceparent'];
        delete (init.headers as Record<string, string>)['Traceparent'];
        delete (init.headers as Record<string, string>)['request-context'];
        delete (init.headers as Record<string, string>)['Request-Context'];
        delete (init.headers as Record<string, string>)['x-account-id'];
        delete (init.headers as Record<string, string>)['X-Account-Id'];
      }
    }

    // Call the original fetch method
    return originalFetch(input, init);
  };
};
