import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { Box, Button, Icon, Modal, Tab, Tabs } from '@mui/material';

import Subscription from '@/components/subscription/Subscription';
import useArtist from '@/hooks/artist/useArtist';
import useAccountContext from '@/hooks/context/useAccountContext';
import useAccountPlaylistPitch from '@/hooks/playlist/useAccountPlaylistPitches';
import { InsightsTabs } from '@/models/Enums';

import PlaylistingFlowButton from '../buttons/PlaylistingFlowButton';
import SocialAdsButtons from '../buttons/SocialAdsButtons';
import ButtonComponent from '../microcomponents/Button';
import Card from '../microcomponents/Card';
import { ActivitiesMenu } from '../modals/ActivitiesMenu';
import MenuModal from './MenuModal';

const UserHeader = ({
  title,
  isProfile,
  isHome,
  isInsights,
  isReleaseCycle,
  isPlaylisting,
  isSocialAds,
  switchView,
}: {
  title?: string;
  isProfile?: boolean;
  isHome?: boolean;
  isInsights?: boolean;
  isReleaseCycle?: boolean;
  isPlaylisting?: boolean;
  isSocialAds?: boolean;
  switchView?: () => void;
}) => {
  const navigate = useNavigate();
  const { account } = useAccountContext();
  const { artist } = useArtist();
  const [params, setSearchParams] = useSearchParams();
  const tab = params.get('tab');

  const { t } = useTranslation();
  const [open, setOpen] = useState<boolean>(false);
  const [value, setValue] = useState<string>();

  const [breakPointHit, setBreakPointHit] = useState<boolean>(false);

  const { accountPlaylistPitch } = useAccountPlaylistPitch({
    continuationToken: '',
  });

  const isTikTok = useMemo(
    () => account?.accessTokens?.filter((item) => item.platform === 'tiktok')[0]?.accessToken,
    [account?.accessTokens]
  );
  const isMeta = useMemo(
    () => account?.accessTokens?.filter((item) => item.platform === 'meta')[0]?.accessToken,
    [account?.accessTokens]
  );

  const handleResize = () => {
    window.visualViewport && window.visualViewport.width <= 900 ? setBreakPointHit(true) : setBreakPointHit(false);
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    handleResize();
  }, []);

  const updateParams = useCallback(() => {
    if (!value) return;
    if (tab && tab !== value) {
      setSearchParams({ tab: value, platform: 'overview' }, { replace: false });
      setValue(tab);
    }
  }, [setSearchParams, tab, value]);

  useEffect(() => {
    if (!isInsights) return;
    updateParams();
  }, [isInsights, updateParams]);

  return (
    <>
      <Modal open={open} onClose={() => setOpen(false)}>
        <>
          <MenuModal closeModalOutput={() => setOpen(false)} />
        </>
      </Modal>
      <Card inner className="p16">
        <div className="d-flex jc-space-between">
          <h3
            className={`title mt-auto mb-auto ${breakPointHit ? 'small' : ''} truncate-1`}
            data-testid="main-card-title"
          >
            {isHome && account?.contact?.firstName ? `Hi, ${account?.contact.firstName}` : t(title || '')}
          </h3>
          <div className="d-flex">
            {isReleaseCycle && (
              <>
                <Button className="m0" onClick={switchView}>
                  Switch view
                </Button>
              </>
            )}

            {!breakPointHit &&
              isPlaylisting &&
              artist &&
              accountPlaylistPitch &&
              accountPlaylistPitch.items.length !== 0 && <PlaylistingFlowButton />}
            {!breakPointHit && isSocialAds && (isMeta || isTikTok) && <SocialAdsButtons />}
            {isHome && <Subscription type="pill" />}
            {isHome && <ActivitiesMenu />}
            {!breakPointHit && (
              <ButtonComponent
                className="mr0 p0 ml8"
                isCustom
                onClick={() => navigate('/profile')}
                ariaLabel="go-to-profile-page"
              >
                <img
                  data-testid="profile-pic"
                  className={isProfile ? 'profile-pic on-profile mt0 mb0 mr0' : 'profile-pic mt0 mb0 mr0'}
                  src={
                    artist?.details?.profileImageUrl
                      ? artist.details.profileImageUrl
                      : '/images/profile-placeholder.svg'
                  }
                  alt=""
                />
              </ButtonComponent>
            )}
            {breakPointHit && (
              <Button
                id="nav-container"
                className="icon-btn mt0 mb0 mr0 no-bg"
                data-testid="menu-button"
                onClick={() => setOpen(true)}
              >
                <Icon className="material-symbols-outlined">menu</Icon>
              </Button>
            )}
          </div>
        </div>
        {breakPointHit &&
          isPlaylisting &&
          artist &&
          accountPlaylistPitch &&
          accountPlaylistPitch.items.length !== 0 && (
            <div className="mt16">
              <PlaylistingFlowButton />
            </div>
          )}
        {breakPointHit && isSocialAds && (isMeta || isTikTok) && (
          <div className="mt16 d-flex">
            <SocialAdsButtons />
          </div>
        )}

        {isInsights && (
          <Box
            sx={{
              borderBottom: 1,
              borderColor: 'divider',
              width: '100%',
              marginTop: '10px',
            }}
          >
            <Tabs
              className="title-tabs"
              value={value || InsightsTabs.SOCIALS}
              onChange={(_, newValue) => setValue(newValue)}
            >
              <Tab value={InsightsTabs.SOCIALS} label={t('COMMON.SOCIALS')} data-testid="insights-socials" />
              <Tab value={InsightsTabs.MUSIC} label={t('COMMON.MUSIC')} data-testid="insights-music" />
            </Tabs>
          </Box>
        )}
      </Card>
    </>
  );
};

export default UserHeader;
