/* eslint-disable @typescript-eslint/no-explicit-any */

import axios from 'axios';

/* eslint-disable no-console */
export const loadToltScript = () => {
  const API_BASE_URL = 'https://58qr5yci46.execute-api.us-east-1.amazonaws.com/v1';
  let publicId: string | null = null;
  let domain: string | null = null;

  interface CommandData {
    command: string;
    data: any;
  }

  // Initialize command queue
  const commandQueue: CommandData[] = [];
  window.tolt_referral = null;

  function enqueueCommand(command: string, data?: any) {
    commandQueue.push({ command, data });
  }

  (window as any).tolt = (window as any).tolt || {
    queue: commandQueue,
    enqueue: enqueueCommand,
  };

  // Utility function for handling element loading
  const waitForElements = (selector: string, callback: (elements: NodeListOf<Element>) => void) => {
    let attempts = 0;
    const interval = setInterval(() => {
      const elements = document.querySelectorAll(selector);
      if (elements.length > 0 || attempts >= 10) {
        clearInterval(interval);
        if (elements.length > 0) callback(elements);
      }
      attempts++;
    }, 500);
  };

  // Stripe Referral Processing
  const processStripeReferrals = (type: 'payment_links' | 'pricing_table' | 'buy_button', stripeDomain?: string) => {
    const selectors = {
      payment_links: stripeDomain
        ? `a[href^="${stripeDomain}"], a[href^="https://buy.stripe.com"]`
        : 'a[href^="https://buy.stripe.com"]',
      pricing_table: 'stripe-pricing-table',
      buy_button: 'stripe-buy-button',
    };

    waitForElements(selectors[type], (elements) => {
      if (window.tolt_referral && elements.length > 0) {
        elements.forEach((el: Element) => {
          if (type === 'payment_links') {
            const anchor = el as HTMLAnchorElement;
            if (!anchor.href.includes('client_reference_id')) {
              anchor.href += anchor.href.includes('?')
                ? `&client_reference_id=${window.tolt_referral}`
                : `?client_reference_id=${window.tolt_referral}`;
            }
          } else {
            if (!el.hasAttribute('client-reference-id')) {
              el.setAttribute('client-reference-id', window.tolt_referral as string);
            }
          }
        });
      }
    });
  };

  // Cookie Handling
  const getCookie = (name: string): string | null => {
    const cookieString = `; ${document.cookie}`;
    const parts = cookieString.split(`; ${name}=`);
    if (parts.length === 2) {
      const rawValue = decodeURIComponent(parts.pop()?.split(';').shift() ?? '');
      try {
        return JSON.parse(rawValue);
      } catch (error) {
        return rawValue;
      }
    }
    return null;
  };

  // API Call Handler
  const sendToAPI = async (endpoint: string, data: any): Promise<any> => {
    if (!publicId || !domain) {
      console.warn('Public ID (data-tolt) or domain is missing. API calls will not be made.');
      return;
    }

    try {
      let url = `${API_BASE_URL}/${endpoint}?id=${window.tolt_referral}`;
      if (endpoint === 'clicks') url = `${API_BASE_URL}/${endpoint}`;
      if (data.email) data.email = decodeURIComponent(data.email);

      const response = await axios.post(
        url,
        { ...data, public_id: publicId },
        {
          headers: { 'Content-Type': 'application/json' },
          transformRequest: (data, headers) => {
            if (headers['Platform-Type']) delete headers['Platform-Type'];
            if (headers['Platform-Version']) delete headers['Platform-Version'];

            return JSON.stringify(data);
          },
        }
      );
      return response.data;
    } catch (error) {
      console.error(`API call to ${endpoint} failed:`, error);
    }
  };

  // Referral Handling
  const processReferral = async () => {
    if (getCookie('tolt_referral') || getCookie('tolt_data')) {
      window.tolt_referral = getCookie('tolt_referral');
      return;
    }

    const params = new URLSearchParams(window.location.search);
    const referralParams = ['tolt', 'ref', 'aff', 'via', 'lmref', 'fpr', 'tap_s', 'afmc', 'f2f-ref'];

    let referralData: any = {};
    for (const param of referralParams) {
      if (params.has(param)) {
        referralData = {
          public_id: publicId,
          param,
          code: params.get(param),
          page: window.location.href,
        };
        break;
      }
    }

    if (Object.keys(referralData).length > 0) {
      const response = await sendToAPI('clicks', referralData);
      if (response?.click_id) {
        const expiryDays = response.cookie_duration || 30;
        console.log('Referral code:', response.referral);

        const expiryDate = new Date();
        expiryDate.setTime(expiryDate.getTime() + expiryDays * 24 * 60 * 60 * 1000);
        const expires = `expires=${expiryDate.toUTCString()}`;

        document.cookie = `tolt_referral=${response.referral}; ${expires}; path=/; domain=${domain}; samesite=none; secure`;
        window.tolt_referral = response.referral;
      }
    }
  };

  // Event Handler
  const handleCommand = async (command: string, data: any) => {
    switch (command) {
      case 'signup':
      case 'conversion':
      case 'clicks':
        await sendToAPI(command, data);
        break;
      default:
        console.warn('Unknown command:', command, data);
    }
  };

  // Initialization
  const initializeTolt = async () => {
    const scriptTag = document.querySelector('script[data-tolt]');
    //   publicId = scriptTag ? scriptTag.getAttribute('data-tolt') : null;
    publicId = 'pk_sioi3qhdVPPUcfsQZRNUbuGQ';

    if (!publicId) {
      console.warn('Public ID (data-tolt) is missing. Script initialization aborted.');
      return;
    }

    const hostname = window.location.hostname;
    domain = hostname.endsWith('.local') ? hostname.split('.').slice(-2).join('.') : hostname;

    await processReferral();

    for (const { command, data } of commandQueue) {
      await handleCommand(command, data);
    }

    window.tolt.queue = [];
    window.tolt = async (command: string, data: any) => {
      await handleCommand(command, data);
    };

    window.tolt.signup = async (email: string) => {
      await handleCommand('signup', { email, public_id: publicId });
    };

    if (scriptTag?.hasAttribute('data-stripe-type')) {
      const stripeType = scriptTag.getAttribute('data-stripe-type') as 'payment_links' | 'pricing_table' | 'buy_button';
      const stripeDomain = scriptTag.getAttribute('data-stripe-domain') || '';
      processStripeReferrals(stripeType, stripeDomain);
    }

    document.dispatchEvent(new Event('toltScriptLoaded'));

    // Load external PSL script
    const pslScript = document.createElement('script');
    pslScript.src = 'https://cdn.jsdelivr.net/npm/psl/dist/psl.min.js';
    pslScript.onload = () => console.log('PSL script loaded successfully.');
    pslScript.onerror = () => console.error('Failed to load the PSL script.');
    document.head.appendChild(pslScript);
  };

  initializeTolt();
};
