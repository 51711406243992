import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';

import { Button, Modal } from '@mui/material';

import WebsiteGeneratorModal from '@/components/promote-modals/WebsiteGeneratorModal';
import useSubscription from '@/hooks/account/useSubscription';
import useBreakpoints from '@/hooks/utility/useBreakpoints';
import { PromoteFlowQueryParams } from '@/models/Enums';

const FanHubFlowButton = ({
  hideButton,
  isOpen,
  close,
}: {
  hideButton?: boolean;
  isOpen?: boolean;
  close?: () => void;
}) => {
  const { t } = useTranslation();
  const { isSubscribed } = useSubscription();
  const [params, setSearchParams] = useSearchParams();
  const { breakpointHit } = useBreakpoints();
  const [open, setOpen] = useState<boolean>(false);

  const showSnackbar = useCallback(() => {
    params.set('subscription', 'true');
    setSearchParams(params);
  }, [params, setSearchParams]);

  useEffect(() => {
    if (isOpen) {
      if (!isSubscribed) {
        showSnackbar();
      } else {
        setOpen(true);
      }
    }
  }, [isOpen, isSubscribed, showSnackbar]);

  return (
    <>
      <Modal
        open={isSubscribed && open}
        onClose={() => {
          setOpen(false);
          close && close();
          params.delete('subscription');
          params.delete(PromoteFlowQueryParams.FAN_HUB);
          setSearchParams(params);
        }}
      >
        <WebsiteGeneratorModal
          closeModalOutput={() => {
            setOpen(false);
            close && close();
            params.delete('subscription');
            params.delete(PromoteFlowQueryParams.FAN_HUB);
            setSearchParams(params);
          }}
        />
      </Modal>
      {!hideButton && (
        <Button
          className={`m0 btn-white min-w130 ${breakpointHit ? 'w100p' : ''}`}
          onClick={() => {
            if (!isSubscribed) {
              showSnackbar();
              return;
            }
            setOpen(true);
          }}
        >
          <span className="">{t('WEBSITE-BUILDER.CREATE-A-FAN-HUB')}</span>
        </Button>
      )}
    </>
  );
};

export default FanHubFlowButton;
