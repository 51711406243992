import React, { CSSProperties, MouseEventHandler } from 'react';
import { useTranslation } from 'react-i18next';

export default function Card({
  id,
  title,
  children,
  className,
  inner,
  innerInner,
  style,
  onClick,
}: {
  id?: string;
  title?: string;
  children?: React.ReactNode;
  className?: string;
  inner?: boolean;
  innerInner?: boolean;
  style?: CSSProperties;
  onClick?: MouseEventHandler<HTMLDivElement>;
}) {
  const { t } = useTranslation();

  return (
    <div
      id={id}
      className={`${inner ? 'card-inner' : innerInner ? 'card-inner-inner' : 'card'}${' '}${className || ''}`}
      style={style}
      onClick={onClick}
    >
      {title && <h4 className="mb20">{t(title)}</h4>}
      {children}
    </div>
  );
}
