import React from 'react';
import { useTranslation } from 'react-i18next';

import { isAfter, isBefore, isSameDay, parseISO } from 'date-fns';

import Card from '../microcomponents/Card';

const ReleaseCycleStatus = ({
  date,
  justStatus,
  isOnCard,
}: {
  date: string;
  justStatus?: boolean;
  isOnCard?: boolean;
}) => {
  const { t } = useTranslation();
  const isPast = isBefore(parseISO(date), parseISO(new Date().toISOString()));
  const isToday = isSameDay(parseISO(date), parseISO(new Date().toISOString()));
  const isFuture = isAfter(parseISO(date), parseISO(new Date().toISOString()));
  const daysUntilRelease = Math.floor((new Date(date).getTime() - new Date().getTime()) / (1000 * 60 * 60 * 24));

  if (justStatus) {
    if (isFuture) {
      return <p className="small text-yellow">• {t('RELEASE-CYCLES.UPCOMING-RELEASE')}</p>;
    }
    if (isToday) {
      return <p className="small text-pink">• {t('RELEASE-CYCLES.RELEASE-DAY')}</p>;
    }
    if (isPast && !isToday) {
      return <p className="small text-neon-green">• {t('RELEASE-CYCLES.POST-RELEASE')}</p>;
    }
  }

  if (isOnCard) {
    return (
      <Card
        className={`text-center p10 br-t-10 br-b-0 ${isFuture ? 'bg-yellow' : ''} ${isToday ? 'bg-neon-green' : ''} ${isPast ? 'bg-neon-green' : ''}`}
      >
        {isFuture && (
          <p className="small text-yellow pt2">• {daysUntilRelease + ' ' + t('RELEASE-CYCLES.UPCOMING-RELEASE')}</p>
        )}
        {isToday && <p className="small text-pink pt2">• {t('RELEASE-CYCLES.RELEASE-DAY')}</p>}
        {isPast && !isToday && <p className="small text-neon-green pt2">• {t('RELEASE-CYCLES.POST-RELEASE')}</p>}
      </Card>
    );
  }
  return (
    <Card inner className="d-flex jc-space-between p16">
      {isFuture && (
        <p className="small text-yellow pt2">• {daysUntilRelease + ' ' + t('RELEASE-CYCLES.UPCOMING-RELEASE')}</p>
      )}
      {isToday && <p className="small text-pink pt2">• {t('RELEASE-CYCLES.RELEASE-DAY')}</p>}
      {isPast && !isToday && <p className="small text-neon-green pt2">• {t('RELEASE-CYCLES.POST-RELEASE')}</p>}
    </Card>
  );
};

export default ReleaseCycleStatus;
