import React, { useEffect, useState } from 'react';
import ReactCountryFlag from 'react-country-flag';

import { Autocomplete, Icon, TextField } from '@mui/material';

import { AllCountries, Country } from '@/constants/Countries';

export const CountrySelector = ({
  outputCountry,
  preselectedCountry,
}: {
  preselectedCountry?: string;
  outputCountry: (country: Country) => void;
}) => {
  const [selectedCountry, setSelectedCountry] = useState<Country | null>();
  const [inputValue, setInputValue] = useState<string>(selectedCountry?.name || '');

  useEffect(() => {
    if (preselectedCountry) {
      const country = AllCountries.find((c) => c.abbr === preselectedCountry);
      setSelectedCountry(country || null);
    }
  }, [preselectedCountry]);

  useEffect(() => {
    if (selectedCountry) {
      outputCountry(selectedCountry);
    }
  }, [outputCountry, selectedCountry]);

  return (
    <Autocomplete
      id="country-select-demo"
      sx={{ color: 'white', borderRadius: '4px !important' }}
      options={AllCountries}
      autoHighlight
      autoComplete={false}
      getOptionLabel={(option) => option.name}
      inputValue={inputValue || ''}
      value={selectedCountry || null}
      popupIcon={<Icon className="text-white">keyboard_arrow_down</Icon>}
      onChange={(_, value) => setSelectedCountry(value)}
      onInputChange={(_, value) => {
        setInputValue(value);
      }}
      renderOption={(props, option) => (
        <li {...props} key={option.abbr} style={{ backgroundColor: '#131313' }}>
          <ReactCountryFlag countryCode={option.abbr} />
          <span className="pl16 text-white">{option.name}</span>
        </li>
      )}
      renderInput={(params) => (
        <TextField
          {...params}
          autoComplete="new-password"
          placeholder={'Choose a country'}
          sx={{
            '& .MuiInputLabel-outlined': {
              paddingLeft: '20px',
              borderColor: 'white',
            },
            '& .MuiInputLabel-shrink': {
              marginLeft: '20px',
              paddingLeft: '10px',
              paddingRight: 0,
              borderColor: 'white',
            },
            '& .MuiAutocomplete-listbox': {
              maxHeight: 200,
              overflow: 'auto',
            },
          }}
          InputLabelProps={params}
          InputProps={{
            ...params.InputProps,
            startAdornment: <Icon className="text-faded">search</Icon>,
          }}
        />
      )}
    />
  );
};
