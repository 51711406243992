import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';

import { Button, Icon, Modal } from '@mui/material';

import { Images } from '@/constants/Images';
import useSubscription from '@/hooks/account/useSubscription';
import useSnackbarContext from '@/hooks/context/useSnackbarContext';
import useUserTracking from '@/hooks/useUserTracking';
import { PartnerPerk } from '@/models/PartnerPerks';

import Card from '../../utility/microcomponents/Card';
import SaveWithProBadge from '../../utility/statuses/SaveWithProBadge';

export const PartnerPerkModal = ({ perk, open, close }: { perk: PartnerPerk; open: boolean; close: () => void }) => {
  const { dispatchSnackbar } = useSnackbarContext();
  const [, setSearchParams] = useSearchParams();
  const { t } = useTranslation();
  const userTracking = useUserTracking();
  const { isSubscribed } = useSubscription();

  const closeModal = () => {
    close();
  };

  return (
    <Modal open={open} onClose={closeModal}>
      <Card innerInner className={`p20 pos-rel h100p`}>
        <Button className="icon-btn pos-abs t0 r0 no-bg" onClick={() => closeModal()}>
          <Icon>close</Icon>
        </Button>
        <h5 className="mb10">{perk.title}</h5>
        <div className="pos-rel">
          <object className="playlist-image w100p h150" data={perk.images.desktop.detail} type="image/png">
            <img src={Images.unhurdFallback} alt={perk.title} className="br8 object-fit-cover w100p h150" />
          </object>
          {perk.saveWithPro && (
            <div className="pos-abs l16 t16">
              <SaveWithProBadge />
            </div>
          )}
        </div>

        <p className="text-faded mt8">{perk.description}</p>
        <ul className="pl20 pr20 mt4 mb50">
          {perk.body.map((item: string, index: number) => (
            <li key={index} className="text-faded small pt0">
              <p className="text-faded small">{item}</p>
            </li>
          ))}
        </ul>

        <div className="b10 l0 d-flex w100p pos-abs flex-wrap gap8 jc-end">
          <div className="p10 w100p">
            {!isSubscribed && (
              <Button
                className="btn-grey w100p m0 mb10"
                onClick={() => {
                  return setSearchParams('subscription=true');
                }}
              >
                {t('COMMON.UNLOCK-PRO-EXCLUSIVE-DISCOUNT')}
              </Button>
            )}
            <Button
              className="btn-white w100p m0"
              onClick={async () => {
                userTracking?.userClickedThroughPartnerPerk(perk);
                if (perk.promotionCode) {
                  await navigator.clipboard.writeText(perk.promotionCode);
                  await dispatchSnackbar({
                    type: 'OPEN_SNACKBAR',
                    payload: {
                      message: perk.promotionCode + ' ' + t('COMMON.COPIED-TO-CLIPBOARD'),
                      type: 'success',
                    },
                  });
                  setTimeout(() => {
                    return window.open(perk.button.url);
                  }, 1000);
                } else {
                  return window.open(perk.button.url);
                }
              }}
            >
              {perk.button.text}
            </Button>
          </div>
        </div>
      </Card>
    </Modal>
  );
};
