import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Button, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, Icon } from '@mui/material';

const DialogModal = ({
  open,
  output,
  title,
  content,
  type,
  isInfoDialog,
  isLink,
  isWebsiteConfirmation,
  isDelete,
  isEndReleaseCycle,
  isDeleteReleaseCycle,
}: {
  open: boolean;
  type?: string;
  output: (value: boolean) => void;
  title: string;
  content?: string;
  isInfoDialog?: boolean;
  isLink?: { link: string; title: string };
  isWebsiteConfirmation?: boolean;
  isDelete?: boolean;
  isEndReleaseCycle?: boolean;
  isDeleteReleaseCycle?: boolean;
}) => {
  const { t } = useTranslation();

  const [dialogOpen, setDialogOpen] = useState<boolean>(false);

  const [isChecked, setIsChecked] = useState<boolean>(false);

  useEffect(() => {
    setDialogOpen(open);
  }, [open]);

  return (
    <>
      <Dialog
        className="p10"
        open={dialogOpen}
        onClose={(_, reason) => {
          if (reason === 'backdropClick') {
            output(false);
            setDialogOpen(false);
          }
          setDialogOpen(false);
        }}
      >
        <DialogTitle className="p0 d-flex jc-space-between">
          <h4>{t(title)}</h4>
          {isWebsiteConfirmation && (
            <Button
              className="icon-btn m0"
              onClick={() => {
                output(false);
                setDialogOpen(false);
              }}
            >
              <Icon>close</Icon>
            </Button>
          )}
        </DialogTitle>
        <DialogContent className="p0">
          <p className="text-faded">{t(content || '')}</p>
          {type === 'permissions' && (
            <div
              className="d-flex mt8"
              onClick={() => {
                setIsChecked(!isChecked);
              }}
            >
              <Checkbox
                data-testid="permissions-checkbox"
                className="text-white pl0"
                checked={isChecked}
                checkedIcon={<Icon className="material-symbols-outlined pl0 text-blue-gradient">check_circle</Icon>}
                icon={<Icon className="material-symbols-outlined pl0 text-faded">radio_button_unchecked</Icon>}
              ></Checkbox>
              <p className="mt10 cursor-pointer">I confirm I have the legal right to represent this artist</p>
            </div>
          )}
          {isLink && (
            <Button
              className="w100p btn-white ml0 mr0 mt20"
              onClick={() => {
                window.open(isLink.link);
              }}
            >
              {t(isLink.title)}
            </Button>
          )}
        </DialogContent>
        <DialogActions className="p0">
          {isInfoDialog && (
            <Button
              className="w100p"
              onClick={() => {
                output(false);
                setDialogOpen(false);
              }}
            >
              {t('COMMON.DONE')}
            </Button>
          )}
          {!isInfoDialog && !isWebsiteConfirmation && !isDelete && !isEndReleaseCycle && !isDeleteReleaseCycle && (
            <Button
              className="btn-white min-w120"
              onClick={() => {
                output(false);
                setDialogOpen(false);
              }}
            >
              {t('COMMON.NO-CONTINUE')}
            </Button>
          )}
          {!isInfoDialog && !isWebsiteConfirmation && !isDelete && !isEndReleaseCycle && !isDeleteReleaseCycle && (
            <Button
              className="min-w120"
              disabled={type === 'permissions' && !isChecked}
              onClick={() => {
                output(true);
                setDialogOpen(false);
              }}
            >
              {t('COMMON.YES-QUIT')}
            </Button>
          )}

          {isWebsiteConfirmation && (
            <Button
              data-testid="permissions-confirm-button"
              className="btn-white min-w120 w100p"
              disabled={type === 'permissions' && !isChecked}
              onClick={() => {
                output(true);
                setDialogOpen(false);
              }}
            >
              {t('COMMON.CONFIRM')}
            </Button>
          )}
          {isDelete && (
            <Button
              className="min-w120"
              onClick={() => {
                output(false);
                setDialogOpen(false);
              }}
            >
              {t('COMMON.GO-BACK')}
            </Button>
          )}
          {isDelete && (
            <Button
              className="btn-white min-w120"
              onClick={() => {
                output(true);
                setDialogOpen(false);
              }}
            >
              <span className="text-error">{t('COMMON.YES-DELETE')}</span>
            </Button>
          )}

          {isEndReleaseCycle && (
            <div className="w100p">
              <Button
                className=" w100p ml0"
                onClick={() => {
                  output(true);
                  setDialogOpen(false);
                }}
              >
                <span className="text-error">{t('RELEASE-CYCLES.YES-END-RELEASE-CYCLE')}</span>
              </Button>
              <Button
                className="w100p ml0 btn-white"
                onClick={() => {
                  output(false);
                  setDialogOpen(false);
                }}
              >
                {t('RELEASE-CYCLES.NO-DONT-END-RELEASE-CYCLE')}
              </Button>
            </div>
          )}

          {isDeleteReleaseCycle && (
            <div className="w100p">
              <Button
                className=" w100p ml0"
                onClick={() => {
                  output(true);
                  setDialogOpen(false);
                }}
              >
                <span className="text-error">{t('RELEASE-CYCLES.YES-DELETE-RELEASE-CYCLE')}</span>
              </Button>
              <Button
                className="w100p ml0 btn-white"
                onClick={() => {
                  output(false);
                  setDialogOpen(false);
                }}
              >
                {t('RELEASE-CYCLES.NO-DONT-DELETE-RELEASE-CYCLE')}
              </Button>
            </div>
          )}
        </DialogActions>
      </Dialog>
    </>
  );
};

export default DialogModal;
