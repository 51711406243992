import React, { useCallback, useEffect, useState } from 'react';

import { Button, Menu } from '@mui/material';

import { platformNameConverter } from '@/formatters/PlatformNameConverter';
import useAccountActivity from '@/hooks/activity/useAccountActivity';
import { ActivityItemModel } from '@/models/Activity';
import { dateTimeFormatter } from '@/utility/date';

import CustomIcons from '../microcomponents/CustomIcons';

export const ActivitiesMenu = () => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const menuOpen = Boolean(anchorEl);

  const [activities, setActivities] = useState<ActivityItemModel[]>([]);

  const { accountActivity } = useAccountActivity();

  const sortActivities = useCallback(() => {
    if (!accountActivity) return;
    if (accountActivity.length > 0) {
      setActivities(accountActivity);
    } else {
      setActivities([
        {
          text: 'No activities found',
          updatedAt: new Date().toISOString(),
          platform: 'unhurd',
          accountId: '',
          type: '',
          image: '',
          id: '',
          createdAt: '',
        },
      ]);
    }
  }, [accountActivity]);

  useEffect(() => {
    sortActivities();
  }, [accountActivity, sortActivities]);

  const openMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const closeMenu = () => {
    setAnchorEl(null);
  };

  if (!accountActivity || accountActivity.length === 0) return null;

  return (
    <>
      <Button
        id="activities-menu-button"
        className="btn-black ml8 mt0 mb0 no-bg icon-btn"
        aria-controls={menuOpen ? 'activities-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={menuOpen ? 'true' : undefined}
        onClick={openMenu}
      >
        <CustomIcons className="mt8" name="notifications" />
      </Button>
      <Menu
        id="activities-menu"
        className="mt20"
        anchorEl={anchorEl}
        open={menuOpen}
        onClose={closeMenu}
        MenuListProps={{
          'aria-labelledby': 'activities-menu-button',
        }}
      >
        {activities &&
          activities.length > 0 &&
          activities.map((activity: ActivityItemModel, index: number) => (
            <div
              key={index}
              className="max-w400 min-w300 mb20 list-item-parent p0"
              style={
                {
                  '--animation-number': `${index}`,
                } as React.CSSProperties
              }
            >
              <div key={index} className="d-flex mb8">
                <img
                  src={
                    activity.platform === 'unhurd'
                      ? '/images/logos/unhurd-logo.png'
                      : `/images/logos/${activity.platform}-logo.svg`
                  }
                  alt={`${activity.platform}`}
                  className="activity-logo"
                />
                <p className={`small text-brand ${activity.platform} pl8 mt-2`}>
                  {platformNameConverter(activity.platform)}
                </p>
                <p className="small ml-auto text-faded">{dateTimeFormatter(activity.updatedAt, 'date')}</p>
              </div>
              <div className="d-flex gap8">
                {activity.image && <img className="br4 mt-auto mb-auto" height="28" src={activity.image} alt="" />}
                <p className="small pt0 mt-auto mb-auto">{activity.text}</p>
              </div>
            </div>
          ))}
      </Menu>
    </>
  );
};
