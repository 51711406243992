import React, { MouseEvent, ReactNode } from 'react';

import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { Button, Icon } from '@mui/material';

import useBreakpoints from '@/hooks/utility/useBreakpoints';

export const SortableItem = ({
  id,
  children,
  isDraggable,
  index,
  onRemove,
}: {
  id: string;
  children: ReactNode;
  isDraggable: boolean;
  index: number;
  onRemove: (e: MouseEvent<HTMLButtonElement>, id: string) => void;
}) => {
  const { attributes, listeners, setNodeRef, transform, transition, isDragging, setActivatorNodeRef } = useSortable({
    id,
  });
  const { breakpointHit } = useBreakpoints();

  const style = {
    transform: CSS.Transform.toString(transform),
    transition: transition || 'transform 100ms ease',
    zIndex: isDragging ? 10 : 1,
    opacity: 1,
    animation: isDraggable && !isDragging ? `wobble 0.5s ${index * 20}ms infinite` : 'none',
    flexGrow: 1,
    flexBasis: 'calc(50% - 5px)',
    maxWidth: breakpointHit ? 'min(calc(50% - 5px), 450px)' : '263px',
    width: breakpointHit ? 'min(calc(50% - 5px), 450px)' : '263px',
    minWidth: breakpointHit ? '160px' : '180px',
  };

  return (
    <div ref={setNodeRef} style={style} {...(isDraggable ? { ...attributes } : {})}>
      {isDraggable && (
        <Button
          className="pos-abs t-20 r-20 z-1000 icon-btn no-bg br50p"
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            onRemove(e, id);
          }}
        >
          <Icon style={{ backgroundColor: '#ffffff', borderRadius: '50%', color: '#000000' }}>close</Icon>
        </Button>
      )}
      <div ref={setActivatorNodeRef} {...(isDraggable ? { ...listeners } : {})}>
        <span>{children}</span>
      </div>
    </div>
  );
};
