import React, { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

import { Icon } from '@mui/material';

import CustomIcons from '../microcomponents/CustomIcons';

const TaskPill = ({
  color,
  content,
  isTimeSensitive,
  isOneTimeTask,
  topTip,
}: {
  color?: string;
  isTimeSensitive?: boolean;
  isOneTimeTask?: boolean;
  topTip?: boolean;
  content?: ReactNode;
}) => {
  const { t } = useTranslation();

  if (isOneTimeTask) {
    return (
      <div className={`task-pill is-one-time`}>
        <p className={`small pt0 fw-bold pl4 pr4`}>{t('RELEASE-CYCLES.ONE-TIME-TASK')}</p>
      </div>
    );
  }
  if (isTimeSensitive) {
    return (
      <div className={`task-pill is-time-sensitive`}>
        <Icon>access_time</Icon>
        <p className={`small pt0 fw-bold pl4 pr4`}>{t('RELEASE-CYCLES.TIME-SENSITIVE')}</p>
      </div>
    );
  }
  if (topTip) {
    return (
      <div className={`task-pill is-top-tip`}>
        <CustomIcons className="p0 mt2" name="lightBulb" />
        <p className={`small pt0 fw-bold pl8`}>{t('RELEASE-CYCLES.TOP-TIP')}</p>
      </div>
    );
  }
  return (
    <div
      className={`task-pill ${isOneTimeTask && 'is-one-time'} ${isTimeSensitive && 'is-time-sensitive'} ${color && color}`}
    >
      <p className={`small pt0 fw-bold pl4 pr4`}>{content}</p>
    </div>
  );
};

export default TaskPill;
