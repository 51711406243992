import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Autocomplete, ClickAwayListener, Icon, TextField, Tooltip } from '@mui/material';

import { RegEx } from '@/constants/RegEx';
import useSnackbarContext from '@/hooks/context/useSnackbarContext';
import { useDebounce } from '@/hooks/useDebounce';
import { SpotifyArtistSearchModel } from '@/models/Spotify';
import OnboardingAPI from '@/network/OnboardingAPI';
import SpotifyAPI from '@/network/SpotifyAPI';
import { handleApiError } from '@/utility/api';

import Loading from '../Loading';

const SpotifySearchArtist = ({
  spotifyArtist,
  isChangeArtist = false,
}: {
  spotifyArtist: (artist: SpotifyArtistSearchModel) => void;
  isChangeArtist?: boolean;
}) => {
  const { t } = useTranslation();
  const [searchQuery, setSearchQuery] = useState<string>('');
  const [query, setQuery] = useState<string>('');
  const noImage = '/images/profile-placeholder.svg';
  const { dispatchSnackbar } = useSnackbarContext();
  const [hasTriedSearch, setHasTriedSearch] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [tooltipOpen, setTooltipOpen] = useState<boolean>(false);
  const [searchOptions, setSearchOptions] = useState<SpotifyArtistSearchModel[]>([
    {
      name: t('SPOTIFY-SEARCH.START-TYPING'),
      images: [{ url: noImage }],
      id: '',
    },
  ]);

  const [chosenArtist, setChosenArtist] = useState<SpotifyArtistSearchModel>({ id: '', name: '' });

  const searchSpotify = useCallback(
    async (query: string) => {
      setIsLoading(true);
      try {
        if (!query) return;
        setTimeout(() => {
          setHasTriedSearch(true);
        }, 3000);
        if (isChangeArtist) {
          const response = await SpotifyAPI.searchArtist({ query: query });
          setSearchOptions(response.data.artists.items);
        } else {
          const response = await OnboardingAPI.searchArtist({ query });
          setSearchOptions(response.data.items);
        }
      } catch (error: unknown) {
        handleApiError({ error, dispatchSnackbar, customMessage: 'Error searching Spotify' });
      }
      setIsLoading(false);
    },
    [dispatchSnackbar, isChangeArtist]
  );

  useEffect(() => {
    searchSpotify(query);
  }, [query, searchSpotify]);

  const getSpotifyArtistByUrl = async (url: string) => {
    setIsLoading(true);
    let result;
    try {
      if (isChangeArtist) {
        result = await SpotifyAPI.getSpotifyArtist({ spotifyArtistUrl: url });
      } else {
        result = await OnboardingAPI.getArtist({ spotifyArtistUrl: encodeURIComponent(url) });
      }
    } catch {
      setSearchOptions([]);
      setSearchQuery(url);
      return setIsLoading(false);
    }

    if (!result) {
      setSearchOptions([]);
      setSearchQuery(url);
      return setIsLoading(false);
    }
    setSearchOptions([result.data]);
    setSearchQuery(url);
    setIsLoading(false);
  };

  const isValidSpotifyUrl = (url: string) => {
    return RegEx.spotifyRegEx.test(url);
  };

  useDebounce(
    () => {
      if (!searchQuery || isValidSpotifyUrl(searchQuery)) return;

      return setQuery(searchQuery);
    },
    300,
    [searchQuery, setQuery]
  );

  const handleChosenArtist = (value?: string | SpotifyArtistSearchModel | null) => {
    if (!value) return;
    if (typeof value === 'string') return;
    setChosenArtist(value);
    spotifyArtist(value);
  };

  return (
    <>
      <label>
        <p>{isChangeArtist ? t('SPOTIFY-SEARCH.CHANGE-SPOTIFY-ARTIST') : ''}</p>
        <Autocomplete
          options={searchOptions}
          data-testid="artist-input"
          getOptionLabel={(option) => (typeof option === 'string' ? option : option.name)}
          isOptionEqualToValue={() => true}
          filterOptions={(options, state) => {
            if (isValidSpotifyUrl(state.inputValue)) {
              return options;
            }
            return options.filter((option) => option.name.toLowerCase().includes(state.inputValue.toLowerCase()));
          }}
          noOptionsText={'Sorry, we could not find any artists'}
          value={chosenArtist}
          inputValue={searchQuery}
          loading={isLoading}
          loadingText={<Loading size="small" />}
          onChange={(_, value: string | SpotifyArtistSearchModel | null | undefined) => handleChosenArtist(value)}
          onInputChange={(_, value) => {
            if (isValidSpotifyUrl(value)) {
              return getSpotifyArtistByUrl(value);
            } else {
              setSearchQuery(value);
            }
          }}
          renderOption={(props, option, index) => (
            <li
              {...props}
              key={option.id}
              data-testid={`artist-selector-item-${index.index}`}
              style={{ backgroundColor: '#131313' }}
            >
              {option.images && option.images.length > 0 ? (
                <img className="search-list-image" src={option.images[0]?.url} alt="" />
              ) : option.image ? (
                <img className="search-list-image" src={option.image?.url} alt="" />
              ) : (
                <img className="search-list-image" src={noImage} alt="" />
              )}
              <span className="pl16 text-white">{option.name}</span>
            </li>
          )}
          renderInput={(params) => (
            <TextField
              {...params}
              placeholder={t('SPOTIFY-SEARCH.SEARCH-FOR-AN-ARTIST')}
              sx={{
                '& .MuiInputLabel-outlined': {
                  paddingLeft: '20px',
                  borderColor: 'white',
                },
                '& .MuiInputLabel-shrink': {
                  marginLeft: '20px',
                  paddingLeft: '10px',
                  paddingRight: 0,
                  borderColor: 'white',
                },
                '& .MuiAutocomplete-listbox': {
                  maxHeight: 200,
                  overflow: 'auto',
                },
              }}
              InputLabelProps={params}
              InputProps={{
                ...params.InputProps,
                startAdornment: <Icon className="text-faded">search</Icon>,
              }}
            />
          )}
        />
      </label>
      {hasTriedSearch && (
        <Tooltip
          arrow
          placement="top"
          open={tooltipOpen}
          title={t('SPOTIFY-SEARCH.CANT-FIND-ARTIST-EXPLANATION')}
          className="cursor-pointer"
        >
          <div
            onClick={(e) => {
              e.stopPropagation();
              setTooltipOpen(!tooltipOpen);
            }}
            onMouseOver={() => {
              setTooltipOpen(true);
            }}
            onMouseLeave={() => {
              setTooltipOpen(false);
            }}
          >
            <ClickAwayListener
              onClickAway={() => {
                setTooltipOpen(false);
              }}
            >
              <div className="text-center cursor-pointer mt8">
                <p className="small text-white">{t('SPOTIFY-SEARCH.CANT-FIND-ARTIST')}</p>
              </div>
            </ClickAwayListener>
          </div>
        </Tooltip>
      )}
    </>
  );
};

export default SpotifySearchArtist;
