import React, { CSSProperties, useCallback, useEffect, useState } from 'react';

import { SpotlightConfig } from '@/models/Walkthrough';

export const BlurOverlay: React.FC<{ selector: string | HTMLElement | null; spotlightConfig?: SpotlightConfig }> = ({
  selector,
  spotlightConfig,
}) => {
  const [target, setTarget] = useState<Element | null>(null);
  const [scrollY, setScrollY] = useState(0);
  const [isSafari, setIsSafari] = useState(false); // Detect Safari

  useEffect(() => {
    if (document.body.classList.contains('safari')) {
      setIsSafari(true);
      return;
    } else if (document.body.classList.contains('chromium')) {
      setIsSafari(false);
      return;
    }
  }, []);

  const findTargetElement = useCallback(() => {
    return selector instanceof HTMLElement ? selector : document.querySelector(selector as string);
  }, [selector]);

  const getHoleRectProps = useCallback(() => {
    if (!target) return null;

    const targetRect = target.getBoundingClientRect();
    const computedStyle = window.getComputedStyle(target);
    const cornerRadius = parseFloat(computedStyle.borderRadius) || 0;

    const paddingTop = spotlightConfig?.padding?.top || 0;
    const paddingBottom = spotlightConfig?.padding?.bottom || 0;
    const paddingLeft = spotlightConfig?.padding?.left || 0;
    const paddingRight = spotlightConfig?.padding?.right || 0;

    return {
      rx: cornerRadius.toString(),
      ry: cornerRadius.toString(),
      x: (targetRect.left - paddingLeft).toString(),
      y: (targetRect.top - paddingTop).toString(),
      width: (targetRect.width + paddingLeft + paddingRight).toString(),
      height: (targetRect.height + paddingTop + paddingBottom).toString(),
    };
  }, [target, spotlightConfig?.padding]);

  const updateHighlightHole = useCallback(() => {
    setScrollY(window.scrollY);
    requestAnimationFrame(() => {
      const holeRect = getHoleRectProps();
      if (!holeRect) return;

      const highlightHole = document.getElementById('highlight-hole');
      if (highlightHole) {
        Object.entries(holeRect).forEach(([key, value]) => {
          highlightHole.setAttribute(key, value);
        });
      }
    });
  }, [getHoleRectProps]);

  useEffect(() => {
    if (!selector) return;

    const initialTarget = findTargetElement();
    if (initialTarget) {
      setTarget(initialTarget);
      return;
    }

    const observer = new MutationObserver(() => {
      const newTarget = findTargetElement();
      if (newTarget) {
        setTarget(newTarget);
        observer.disconnect();
      }
    });

    observer.observe(document.body, { childList: true, subtree: true });

    return () => observer.disconnect();
  }, [findTargetElement, selector]);

  useEffect(() => {
    if (!target) return;

    const observer = new MutationObserver(updateHighlightHole);
    observer.observe(target, { attributes: true, childList: true, subtree: true });

    return () => observer.disconnect();
  }, [target, updateHighlightHole]);

  useEffect(() => {
    if (!target) return;

    const handleScroll = () => updateHighlightHole();
    window.addEventListener('scroll', handleScroll);
    window.addEventListener('resize', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
      window.removeEventListener('resize', handleScroll);
    };
  }, [target, updateHighlightHole]);

  if (!target) return null;

  const holeRect = getHoleRectProps();

  return (
    <>
      <div
        className="blur-filter-overlay"
        style={
          isSafari && holeRect
            ? ({
                clipPath: `polygon(0% 0%, 0% 100%, ${holeRect?.x}px 100%, ${holeRect?.x}px ${Number(holeRect?.y) - scrollY}px, ${Number(holeRect?.x) + Number(holeRect?.width)}px ${Number(holeRect?.y) - scrollY}px, ${Number(holeRect?.x) + Number(holeRect?.width)}px ${Number(Number(holeRect?.y) + scrollY) + Number(holeRect?.height)}px, ${holeRect?.x}px ${Number(Number(holeRect?.y) + scrollY) + Number(holeRect?.height)}px, ${holeRect?.x}px 100%, 100% 100%, 100% 0%)`,
              } as CSSProperties)
            : {}
        }
      />

      <svg className="highlight-svg" width="100%" height="100%">
        <defs>
          <mask id="highlight-mask">
            <rect x="0" y="0" width="100%" height="100%" fill="white" />
            <rect
              id="highlight-hole"
              fill="black"
              rx={holeRect?.rx}
              ry={holeRect?.ry}
              x={holeRect?.x}
              y={holeRect?.y}
              width={holeRect?.width}
              height={holeRect?.height}
            />
          </mask>
        </defs>
        <rect x="0" y="0" width="100%" height="100%" fill="rgba(0, 0, 0, 0.5)" mask="url(#highlight-mask)" />
      </svg>
    </>
  );
};
