import React, { useEffect, useState } from 'react';
import { Outlet, useNavigate, useSearchParams } from 'react-router-dom';

import { getAuth } from 'firebase/auth';

import { ReleaseCycleButton } from '@/components/release-cycle/ReleaseCycleFlow';
import Subscription from '@/components/subscription/Subscription';
import { AllowedUnverifiedEmails } from '@/constants/Emails';
import IntercomProvider from '@/contexts/IntercomContext';
import SurveyProvider from '@/contexts/SurveyContext';
import useAccountContext from '@/hooks/context/useAccountContext';
import { PromoteFlowQueryParams } from '@/models/Enums';

import FanHubFlowButton from '../buttons/FanHubFlowButton';
import PlaylistingFlowButton from '../buttons/PlaylistingFlowButton';
import SocialAdsButtons from '../buttons/SocialAdsButtons';
import Loading from '../Loading';
import NavBar from '../navigation/NavBar';

import { auth } from '@/firebaseSetup';

const ProtectedRoute = () => {
  const navigate = useNavigate();
  const [params, setSearchParams] = useSearchParams();
  const [isFanHubOpen, setIsFanHubOpen] = useState<boolean>(false);
  const [isMetaAdsOpen, setIsMetaAdsOpen] = useState<boolean>(false);
  const [isTikTokAdsOpen, setIsTikTokAdsOpen] = useState<boolean>(false);
  const [isPlaylistingOpen, setIsPlaylistingOpen] = useState<boolean>(false);

  const closeFlows = () => {
    params.delete(PromoteFlowQueryParams.FAN_HUB);
    params.delete(PromoteFlowQueryParams.META_ADS);
    params.delete(PromoteFlowQueryParams.TIKTOK_ADS);
    params.delete(PromoteFlowQueryParams.PLAYLISTING);
    params.delete(PromoteFlowQueryParams.SUBSCRIPTION);
    setSearchParams(params);
    setIsFanHubOpen(false);
    setIsMetaAdsOpen(false);
    setIsTikTokAdsOpen(false);
    setIsPlaylistingOpen(false);
  };

  useEffect(() => {
    if (params.get(PromoteFlowQueryParams.FAN_HUB) === 'true') {
      setIsFanHubOpen(true);
    } else {
      setIsFanHubOpen(false);
    }
    if (params.get(PromoteFlowQueryParams.META_ADS) === 'true') {
      setIsMetaAdsOpen(true);
    } else {
      setIsMetaAdsOpen(false);
    }
    if (params.get(PromoteFlowQueryParams.TIKTOK_ADS) === 'true') {
      setIsTikTokAdsOpen(true);
    } else {
      setIsTikTokAdsOpen(false);
    }
    if (params.get(PromoteFlowQueryParams.PLAYLISTING) === 'true') {
      setIsPlaylistingOpen(true);
    } else {
      setIsPlaylistingOpen(false);
    }
  }, [params]);

  const { isAuthenticated, dispatchFirebaseAccountId, logoutUser } = useAccountContext();

  useEffect(() => {
    if (isAuthenticated) return;

    getAuth().onAuthStateChanged(async () => {
      if (!auth.currentUser) {
        logoutUser();
        return;
      }
      await dispatchFirebaseAccountId(auth.currentUser.uid);

      if (
        auth.currentUser &&
        !auth.currentUser.emailVerified &&
        AllowedUnverifiedEmails.includes(auth.currentUser.email || '') === false
      ) {
        navigate('/email-verification');
        return;
      }
    });
  }, [dispatchFirebaseAccountId, isAuthenticated, logoutUser, navigate]);

  if (!isAuthenticated) {
    return (
      <div className="centered-loading-main h100p">
        <Loading />
      </div>
    );
  }

  return (
    <IntercomProvider>
      <SurveyProvider>
        <Subscription type="element" />
        <FanHubFlowButton hideButton isOpen={isFanHubOpen} close={closeFlows} />
        <SocialAdsButtons hideButton isOpenMeta={isMetaAdsOpen} isOpenTikTok={isTikTokAdsOpen} close={closeFlows} />
        <PlaylistingFlowButton hideButton isOpen={isPlaylistingOpen} close={closeFlows} />
        <ReleaseCycleButton entryType="hidden" />
        <NavBar />
        <Outlet />
      </SurveyProvider>
    </IntercomProvider>
  );
};

export default ProtectedRoute;
