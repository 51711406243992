import React, { CSSProperties } from 'react';

import { Tooltip } from '@mui/material';

interface IProgressProps {
  color?: string;
  tooltip?: string;
  progress?: number;
  label?: string | number;
  title?: string;
  bubble?: boolean;
  circle?: boolean;
  progressLg?: number;
  progressMd?: number;
  progressSm?: number;
  progressDate?: number;
  progressButton?: number;
  isPast?: boolean;
  isSelected?: boolean;
}

const LARGE_RADIAL_DASH_COUNT = 560;
const MEDIUM_RADIAL_DASH_COUNT = 435;
const SMALL_RADIAL_DASH_COUNT = 310;
const DATE_RADIAL_DASH_COUNT = 80;
const BUTTON_RADIAL_DASH_COUNT = 120;

const ProgressBar = ({
  color,
  progress,
  label,
  title,
  bubble,
  circle,
  progressLg = 0,
  progressMd = 0,
  progressSm = 0,
  progressDate,
  progressButton,
  tooltip,
  isSelected,
}: IProgressProps) => {
  const style = { '--width': `${progress}%` } as CSSProperties;

  const formatPercentage = (progress: number, radialCount: number) => {
    return radialCount - (progress * radialCount) / 100;
  };

  const radialStyleLg = {
    '--progressLg': formatPercentage(progressLg, LARGE_RADIAL_DASH_COUNT),
    strokeDasharray: LARGE_RADIAL_DASH_COUNT,
    strokeDashoffset: formatPercentage(progressLg, LARGE_RADIAL_DASH_COUNT),
  } as CSSProperties;
  const radialStyleMd = {
    '--progressMd': formatPercentage(progressMd, MEDIUM_RADIAL_DASH_COUNT),
    strokeDasharray: MEDIUM_RADIAL_DASH_COUNT,
    strokeDashoffset: formatPercentage(progressMd, MEDIUM_RADIAL_DASH_COUNT),
  } as CSSProperties;
  const radialStyleSm = {
    '--progressSm': formatPercentage(progressSm, SMALL_RADIAL_DASH_COUNT),
    strokeDasharray: SMALL_RADIAL_DASH_COUNT,
    strokeDashoffset: formatPercentage(progressSm, SMALL_RADIAL_DASH_COUNT),
  } as CSSProperties;
  const radialStyleDate = {
    '--progressSm': formatPercentage(progressDate || 0, DATE_RADIAL_DASH_COUNT),
    strokeDasharray: DATE_RADIAL_DASH_COUNT,
    strokeDashoffset: formatPercentage(progressSm, DATE_RADIAL_DASH_COUNT),
  } as CSSProperties;
  const radialStyleButton = {
    '--progressSm': formatPercentage(progressButton || 0, BUTTON_RADIAL_DASH_COUNT),
    strokeDasharray: BUTTON_RADIAL_DASH_COUNT,
    strokeDashoffset: formatPercentage(progressSm, BUTTON_RADIAL_DASH_COUNT),
  } as CSSProperties;

  return (
    <>
      {/* Linear Progress Bars */}
      {!circle && (
        <div className="progress-bar-container">
          {title && <p className="text-faded min-w70">{title}</p>}
          <Tooltip title={tooltip ? `${tooltip}%` : `${progress?.toFixed(2)}%`} arrow placement="top">
            <div className="progress-bar">
              <div
                className={
                  color === 'yellow'
                    ? 'yellow background inner-bar pos-abs'
                    : color === 'pink'
                      ? 'pink background inner-bar pos-abs'
                      : 'background inner-bar pos-abs'
                }
              ></div>
              <div
                className={
                  color === 'yellow'
                    ? 'yellow foreground inner-bar pos-abs'
                    : color === 'pink'
                      ? 'pink foreground inner-bar pos-abs'
                      : 'foreground inner-bar pos-abs'
                }
                style={style}
              ></div>
              {bubble && (
                <div className={color === 'yellow' ? 'yellow bubble pos-abs' : 'bubble pos-abs'} style={style}>
                  <div className={'inner-bubble pos-rel'} style={style}></div>
                </div>
              )}
            </div>
          </Tooltip>

          {(label || label === 0) && <p className="text-faded small">{label}</p>}
        </div>
      )}
      {/* Radial Progress bars */}
      {circle && (
        <section className={progressDate ? '' : 'mt16'}>
          <svg className="radial-progress">
            <defs>
              <linearGradient id="blue-gradient" x1="0%" y1="0%" x2="0%" y2="100%">
                <stop offset="0%" stopColor="#406bff" />
                <stop offset="100%" stopColor="#06befe" />
              </linearGradient>
              <linearGradient id="yellow-gradient" x1="0%" y1="0%" x2="0%" y2="100%">
                <stop offset="0%" stopColor="#ffae18" />
                <stop offset="100%" stopColor="#fdd07a" />
              </linearGradient>
              <linearGradient id="pink-gradient" x1="0%" y1="0%" x2="0%" y2="100%">
                <stop offset="0%" stopColor="#f8005e" />
                <stop offset="100%" stopColor="#f8629b" />
              </linearGradient>
              <linearGradient id="white-gradient" x1="0%" y1="0%" x2="0%" y2="100%">
                <stop offset="0%" stopColor="#ffffff" />
                <stop offset="100%" stopColor="#ffffff" />
              </linearGradient>
            </defs>
            {progressDate === undefined && progressButton === undefined && (
              <>
                {(progressLg || progressLg === 0) && (
                  <circle className="lg" id="track" cx="100" cy="100" r="90" stroke="url(#blue-gradient)" />
                )}
                {(progressLg || progressLg === 0) && (
                  <Tooltip title={`Accepted: ${progressLg.toFixed(2)}%`} arrow placement="top">
                    <circle
                      className="lg"
                      id="progress"
                      cx="100"
                      cy="100"
                      r="90"
                      stroke="url(#blue-gradient)"
                      style={radialStyleLg}
                    />
                  </Tooltip>
                )}

                {(progressMd || progressMd === 0) && (
                  <circle className="md" id="track" cx="100" cy="100" r="70" stroke="url(#yellow-gradient)" />
                )}
                {(progressMd || progressMd === 0) && (
                  <Tooltip title={`Pending: ${progressMd.toFixed(2)}%`} arrow placement="top">
                    <circle
                      className="md"
                      id="progress"
                      cx="100"
                      cy="100"
                      r="70"
                      stroke="url(#yellow-gradient)"
                      style={radialStyleMd}
                    />
                  </Tooltip>
                )}

                {(progressSm || progressSm === 0) && (
                  <circle className="sm" id="track" cx="100" cy="100" r="50" stroke="url(#pink-gradient)" />
                )}
                {(progressSm || progressSm === 0) && (
                  <Tooltip title={`Rejected: ${progressSm.toFixed(2)}%`} arrow placement="top">
                    <circle
                      className="sm"
                      id="progress"
                      cx="100"
                      cy="100"
                      r="50"
                      stroke="url(#pink-gradient)"
                      style={radialStyleSm}
                    />
                  </Tooltip>
                )}
              </>
            )}

            {(progressDate || (progressDate === 0 && !progressButton)) && (
              <Tooltip title={`Tasks completed: ${progressDate.toFixed(2)}%`} arrow placement="top">
                <circle
                  className="date"
                  id="progress"
                  cx="14"
                  cy="16"
                  r="13"
                  opacity={isSelected ? 0.6 : 0.4}
                  stroke={
                    isSelected
                      ? 'url(#white-gradient)'
                      : progressDate === 100
                        ? 'url(#blue-gradient)'
                        : 'url(#white-gradient)'
                  }
                  style={radialStyleDate}
                />
              </Tooltip>
            )}
            {(progressButton || progressButton === 0) && (
              <Tooltip title={`Tasks completed: ${progressButton.toFixed(2)}%`} arrow placement="top">
                <circle
                  className="button"
                  id="progress"
                  cx="20"
                  cy="20"
                  r="19"
                  stroke={'url(#blue-gradient)'}
                  style={radialStyleButton}
                />
              </Tooltip>
            )}
          </svg>
        </section>
      )}
    </>
  );
};

export default ProgressBar;
