import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Button, Icon, Modal } from '@mui/material';

import useMeetTheCurator from '@/hooks/playlist/useMeetTheCurator';

import Loading from '../Loading';
import ButtonComponent from '../microcomponents/Button';

const MeetTheCurator = ({ id, name }: { id: string; name: string }) => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const { curatorDetails, curatorDetailsIsLoading, curatorDetailsError } = useMeetTheCurator({
    curatorSpotifyId: id,
  });

  const onClose = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    setIsOpen(false);
  };

  return (
    <>
      {curatorDetails && (curatorDetails.bio || curatorDetails.vibe) && (
        <p className="small text-faded">
          {t('PLAYLISTING.MEET-THE-CURATOR')}:{' '}
          <span>
            <ButtonComponent
              isCustom
              className="pl0"
              onClick={(e) => {
                e.stopPropagation();
                setIsOpen(true);
              }}
            >
              <p className="underline small text-white">{name}</p>
            </ButtonComponent>
          </span>
        </p>
      )}
      {(curatorDetailsError || (curatorDetails && !curatorDetails.bio && !curatorDetails.vibe)) && (
        <p className="small text-faded">by {name}</p>
      )}

      <Modal open={isOpen} onClose={onClose}>
        <div className="to-do-modal" onClick={(e) => e.stopPropagation()}>
          <Button className="icon-btn m0 mt-10 mr-10 pos-abs r16" onClick={onClose}>
            <Icon>close</Icon>
          </Button>

          {curatorDetailsIsLoading && <Loading size="small" />}
          {curatorDetails && !curatorDetailsIsLoading && (
            <>
              <div className="d-flex gap8">
                <img src={curatorDetails.image} alt={curatorDetails.name} height={80} width={80} className="br8" />

                <div className="mt-auto mb-auto">
                  <p className="text-faded">{t('PLAYLISTING.MEET-THE-CURATOR')}</p>
                  <h5 className="fw-bold">{name}</h5>
                </div>
              </div>
              {curatorDetails.bio && (
                <>
                  <p className="mt16 fw-bold">{t('COMMON.BIO')}</p>
                  <p className="small text-faded">{curatorDetails.bio}</p>
                </>
              )}
              {curatorDetails.vibe && (
                <>
                  <p className="mt16 fw-bold">{t('COMMON.VIBE')}</p>
                  <p className="small text-faded">{curatorDetails.vibe}</p>
                </>
              )}
              {!curatorDetails.bio && !curatorDetails.vibe && (
                <p className="mt16">{t('PLAYLISTING.NO-INFO-ABOUT-CURATOR')}</p>
              )}
            </>
          )}
        </div>
      </Modal>
    </>
  );
};

export default MeetTheCurator;
